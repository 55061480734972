.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-link {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: black;
  text-decoration: none;
}

body {
  text-align: center;
}


#note-name-input, #folder-name-input {
  margin-left: 5px;
}

textarea {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}

.note-content {
  display: block;
  margin: 5px 0;
}

.error {
  border: 2px solid red;
  padding: 3px;
  background-color: pink;
}

.error-button {
  margin-left: 5px;
  border: 1px solid red;
  vertical-align: middle;
  margin-bottom: 4px;
}